export const get_phases = () => ({
  'concept': {
    title: 'Concept',
  },
  'committed_concept': {
    title: 'Vastgesteld concept'
  },
  'published': {
    title: 'Definitief gepubliceerd'
  },
  'active': {
    title: 'Definitief actief'
  },
  'archived': {
    title: 'Archief'
  }
});
